import globalContants from "constants/constants";
import { is_empty } from "utils/validations";
import {
  COMMUNICATION_TIMING_AFTER_EVENT,
  COMM_SCHEDULE,
  COMM_SCHEDULE_TIMING_MAP,
  COMM_TIMING_CHOICE_DURATION_TYPE_MAP,
  DURATION_TYPE_FROM_TIMING_CHOICE_AFTER_EVENT,
  DURATION_TYPE_FROM_TIMING_CHOICE_BEFORE_EVENT,
  batch_data,
  batch_uids,
  comms,
  constants,
  default_page_number,
  slot_data,
  slot_uids,
} from "./mangageCommunication.constants";
import { dataProvider, api } from "data";
import { schedule_type_names, schedule_types_ids } from "constants/schedule";
import { appendUrlParams } from "utils/urlUtils";
import { DEFAULT_COMM_TIMING } from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import { app_pathnames, app_route_keys } from "constants/urlPaths";
import { isRequestSuccessful } from "utils/Utils";
import { apiMethods } from "data/api.constants";
import { notification_color_keys } from "utils/hooks";
import { FORM_KEYS } from "./components/CreateCommunication/CreateCommunication.constants";

/**
 * Get user details properties for a listing.
 *
 * @param {Object} listingDetails - The details of the listing.
 * @param {Object} senderDetails - The details of the sender.
 * @returns {Object} An object containing user details properties.
 */
export const getLisitngUserDetailsProps = (listingDetails, senderDetails) => {
  return {
    img: {
      src: listingDetails.cover_image,
      alt: "Offering Display Image",
    },
    title: listingDetails.title,
    description: globalContants?.schedule_type[listingDetails.type]?.name,
    items: [
      {
        label: "Sender name:",
        value: `${senderDetails.first_name} ${senderDetails.last_name}`,
        uuid: "Sender name",
      },
      {
        label: "Sender email:",
        value: senderDetails.email,
        uuid: "Sender email",
      },
    ],
    type: schedule_type_names[listingDetails?.type],
    request_groups: listingDetails?.request_groups,
  };
};

/**
 * Handles sending an email communication.
 *
 * @param {Object} props - An object containing various properties and settings.
 * @returns {Promise<void>} A Promise that resolves when the email communication is sent.
 */
export const handleSendEmail = async (props) => {
  const {
    editorState,
    getHtmlFromEditorState,
    emptyHtmlWithListStyle,
    emptyHtml,
    emailSubjectRef,
    duration,
    duration_fixed: immediately,
    duration_type,
    type,
    listing_uuid,
    handleGoBack,
    notify,
    offering_type,
    senderName,
    senderEmail,
    offering_variant_payload,
    [FORM_KEYS.audience_type]: audienceType,
    [FORM_KEYS.instalments_payload]: instalmentsPayload,
  } = props;

  const email_body = getHtmlFromEditorState(editorState);
  const isEditorEmpty =
    email_body === emptyHtmlWithListStyle || email_body === emptyHtml;

  // Extracting variables from email body as the api demands to send in an array
  const variablesUsedInEmail = [];
  const regex = /{{([^}]*)}}/g;
  const matches = email_body.matchAll(regex);
  for (const match of matches) {
    variablesUsedInEmail.push(match[1]);
  }

  if (is_empty(emailSubjectRef.current) || is_empty(emailSubjectRef.current)) {
    notify("Please enter the Email Subject to Continue");
    return false;
  }

  if (is_empty(email_body) || isEditorEmpty) {
    notify("Please enter the Email Body to Continue");
    return false;
  }

  let payload = {
    drip_type: constants.drip_types[type],
    listing_uuid: listing_uuid,
    comm_schedule: COMM_SCHEDULE_TIMING_MAP[duration_type],
    comm_timing_choice: COMM_TIMING_CHOICE_DURATION_TYPE_MAP[duration_type],
    comm_timing: Number(duration),
    offering_variant_payload,
    email_drip_data: {
      email_subject: emailSubjectRef.current,
      email_body: email_body,
      sender_name: senderName,
      email_from: senderEmail,
      audience: {
        all_customers: "All Customers",
      },
      variables_used: variablesUsedInEmail,
    },
    [FORM_KEYS.audience_type]: audienceType,
    [FORM_KEYS.instalments_payload]: instalmentsPayload,
  };

  if (immediately === "true" || immediately) {
    payload.comm_schedule = constants.comm_schedule[comms.COMM_IMMEDIATELY];
    payload.comm_timing_choice = null;
    payload.comm_timing = 0;
  }

  switch (offering_type) {
    case schedule_type_names[schedule_types_ids.workshop]: {
      payload.slot_uids = props?.session_batches;
      break;
    }
    case schedule_type_names[schedule_types_ids.rolling_class]: {
      payload.batch_uids = props?.session_batches;
      break;
    }
    case schedule_type_names[schedule_types_ids.group_class]: {
      payload.batch_uids = props?.session_batches;
      break;
    }
  }

  try {
    const { status, message } = await dataProvider.custom_request(
      api.listingSpecificCommunication.comm_drip,
      apiMethods.POST,
      payload
    );

    if (isRequestSuccessful(status)) {
      notify("Created Comm Successfully", "info");
      handleGoBack();
      setCommDataSession({
        openCommModal: false,
      });
    } else {
      notify(message, notification_color_keys.error);
    }
  } catch (er) {
    notify(er?.message || "Server Error", notification_color_keys.error);
  }
};

/**
 * Handles the editing of an email communication.
 *
 * @param {Object} props - An object containing various properties and settings.
 * @returns {Promise<void>} A Promise that resolves when the email communication is successfully edited.
 */
export const handleEditEmail = async (props) => {
  const {
    editorState,
    getHtmlFromEditorState,
    emptyHtmlWithListStyle,
    emptyHtml,
    emailSubjectRef,
    duration,
    duration_fixed: immediately,
    duration_type,
    session_batches = [],
    type,
    listing_comm_uuid,
    handleGoBack,
    notify,
    offering_type,
    variableList,
    offering_variant_payload,
    [FORM_KEYS.audience_type]: audienceType,
    [FORM_KEYS.instalments_payload]: instalmentsPayload,
  } = props;
  const email_body = getHtmlFromEditorState(editorState);

  const isEditorEmpty =
    email_body === emptyHtmlWithListStyle || email_body === emptyHtml;

  // Extracting variables from email body as the api demands to send in an array
  const regex = /<cite[^>]*>(.*?)<\/cite>|(\$?\{\{\w+\}\}\$?)/g;
  const matches = [...email_body.matchAll(regex)];
  const result = matches.map((match) => match[1] || match[2]);

  const variablesUsedInEmail = result.reduce((previous, current) => {
    const resultArr = [...previous];
    const key = current.replace(/\$|{|}/g, "");
    if (key in variableList) {
      resultArr.push(variableList[key]);
    } else {
      resultArr.push(key);
    }

    return resultArr;
  }, []);

  if (is_empty(emailSubjectRef.current) || is_empty(emailSubjectRef.current)) {
    notify("Please enter the Email Subject to Continue");
    return false;
  }

  if (is_empty(email_body) || isEditorEmpty) {
    notify("Please enter the Email Body to Continue");
    return false;
  }

  const commSchedulePayload = {
    listing_comm_uid: listing_comm_uuid,
    updated_values: {
      offering_variant_payload,
      drip_type: constants.drip_types[type],
      comm_schedule: COMM_SCHEDULE_TIMING_MAP[duration_type],
      comm_timing_choice: COMM_TIMING_CHOICE_DURATION_TYPE_MAP[duration_type],
      comm_timing: Number(duration) || 0,
      [FORM_KEYS.audience_type]: audienceType,
      [FORM_KEYS.instalments_payload]: instalmentsPayload,
    },
  };

  if (immediately === "true" || immediately) {
    commSchedulePayload.updated_values.comm_schedule =
      constants.comm_schedule[comms.COMM_IMMEDIATELY];
    commSchedulePayload.updated_values.comm_timing_choice = null;
    commSchedulePayload.updated_values.comm_timing = 0;
  }

  switch (offering_type) {
    case schedule_type_names[schedule_types_ids.workshop]: {
      commSchedulePayload.updated_values.slot_data = session_batches;
      break;
    }
    case schedule_type_names[schedule_types_ids.rolling_class]: {
      commSchedulePayload.updated_values.batch_data = session_batches;
      break;
    }
    case schedule_type_names[schedule_types_ids.group_class]: {
      commSchedulePayload.updated_values.batch_data = session_batches;
      break;
    }
  }

  try {
    const scheduleResponse = await dataProvider.custom_request(
      api.listingSpecificCommunication.comm_update_schedule,
      apiMethods.POST,
      commSchedulePayload
    );

    const payload = {
      listing_comm_uid: listing_comm_uuid,
      updated_values: {
        email_subject: emailSubjectRef.current,
        email_body: email_body,
        attachment_data: [],
        variables_used: variablesUsedInEmail,
      },
    };

    const { status } = await dataProvider.custom_request(
      api.listingSpecificCommunication.comm_update,
      apiMethods.POST,
      payload
    );

    if (
      isRequestSuccessful(scheduleResponse.status) &&
      isRequestSuccessful(status)
    ) {
      notify("Edited Comm Successfully", "info");
      setCommDataSession({
        openCommModal: false,
      });
      handleGoBack();
    }
  } catch (err) {
    notify("Server Error", notification_color_keys.error);
  }
};

/**
 * Request and retrieve a list of communications data of a specific type.
 *
 * @param {string} api - The API endpoint to request data from.
 * @param {string} type - The type of communication (e.g., COMM_EMAIL, COMM_WHATSAPP).
 * @param {number} page - The page number to retrieve.
 * @param {string} drip_type - The drip type of the communication.
 * @param {string} listing_uuid - The UUID of the listing for which to retrieve data.
 * @param {function} setLoading - A function to set the loading state.
 * @returns {Promise<Array>} A Promise that resolves to an array of communications data.
 */
export const requestListData = async (
  api,
  type,
  drip_type,
  listing_uuid,
  setLoading
) => {
  const response = await dataProvider.custom_request(api, apiMethods.GET, {
    page: default_page_number,
    drip_type: drip_type,
    listing_uid: listing_uuid,
  });
  if (isRequestSuccessful(response.status)) {
    let data;
    switch (type) {
      case comms.COMM_EMAIL:
        data = response.data.listing_comm_emails;
        break;
      case comms.COMM_WHATSAPP:
        data = response.data.page_data;
        break;
    }
    setLoading(false);
    return data;
  }
};

/**
 * Delete a communication by its unique identifier.
 *
 * @param {string} listing_comm_uid - The unique identifier of the communication to delete.
 * @returns {Promise<Object>} A Promise that resolves to an object representing the delete response.
 */
export const deleteComm = async (listing_comm_uid) => {
  if (!is_empty(listing_comm_uid)) {
    try {
      const response = await dataProvider.custom_request(
        api.listingSpecificCommunication.comm_delete,
        apiMethods.POST,
        {
          listing_comm_uid: listing_comm_uid,
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  }
};

/**
 * Manage WhatsApp communication, including creation and update.
 *
 * @param {Object} specificComms - The specific communications data.
 * @param {Object} payload - The payload for WhatsApp communication.
 * @param {function} notify - A function to notify with messages.
 * @param {Object} history - The history object for navigation.
 * @param {function} setCommDataSession - A function to set communication data session.
 * @param {boolean} isEditMode - Indicates whether it's in edit mode.
 * @returns {Promise<void>} A Promise that resolves after managing the WhatsApp communication.
 */
export const manageWhatsappComm = async (
  specificComms,
  payload,
  notify,
  history,
  setCommDataSession,
  isEditMode
) => {
  if (is_empty(payload)) return;
  if (is_empty(payload.template_uid)) {
    payload.template_uid = specificComms.template_uid;
  }
  let scheduleSpecificpayload = {
    drip_type: constants.drip_types[specificComms.type],
    comm_schedule: COMM_SCHEDULE_TIMING_MAP[specificComms.duration_type],
    comm_timing_choice:
      COMM_TIMING_CHOICE_DURATION_TYPE_MAP[specificComms.duration_type],
    comm_timing: Number(specificComms.duration),
  };
  let specificCommsPayload = {
    listing_uuid: specificComms?.listing_uuid,
    whatsapp_drip_data: payload,
  };

  if (specificComms.duration_fixed) {
    scheduleSpecificpayload.comm_schedule =
      constants.comm_schedule[comms.COMM_IMMEDIATELY];
    scheduleSpecificpayload.comm_timing_choice = null;
    scheduleSpecificpayload.comm_timing = DEFAULT_COMM_TIMING;
  }

  const session_batches_payload = specificComms?.session_batches;
  const slotKey = isEditMode ? slot_data : slot_uids;
  const batchKey = isEditMode ? batch_data : batch_uids;

  switch (specificComms.offering_type) {
    case schedule_type_names[schedule_types_ids.workshop]: {
      scheduleSpecificpayload[slotKey] = session_batches_payload;
      break;
    }
    case schedule_type_names[schedule_types_ids.rolling_class]: {
      scheduleSpecificpayload[batchKey] = session_batches_payload;
      break;
    }
    case schedule_type_names[schedule_types_ids.group_class]: {
      scheduleSpecificpayload[batchKey] = session_batches_payload;
      break;
    }
  }

  let apiUrl = api.listingSpecificCommunication.comm_drip;
  const offering_variant_payload = specificComms?.offering_variant_payload;
  const {
    [FORM_KEYS.audience_type]: audienceType,
    [FORM_KEYS.instalments_payload]: instalmentsPayload,
  } = specificComms;

  if (isEditMode) {
    apiUrl = api.listingSpecificCommunication.whatsapp_comm_update;
    let updatedValues = {
      audience: payload.audience,
      title: payload.title,
      variable_values: payload.variable_values,
      template_uid: payload.template_uid,
      custom_data: payload.custom_data,
      sample_user_data: payload.sample_user_data,
    };
    specificCommsPayload = {
      listing_comm_uid: specificComms.listing_comm_uuid,
      updated_values: updatedValues,
    };
    scheduleSpecificpayload = {
      listing_comm_uid: specificComms.listing_comm_uuid,
      updated_values: {
        ...scheduleSpecificpayload,
        offering_variant_payload,
        [FORM_KEYS.audience_type]: audienceType,
        [FORM_KEYS.instalments_payload]: instalmentsPayload,
      },
    };
    const scheduleResponse = await dataProvider.custom_request(
      api.listingSpecificCommunication.comm_update_schedule,
      apiMethods.POST,
      scheduleSpecificpayload
    );
    if (!isRequestSuccessful(scheduleResponse.status)) {
      return;
    }
  } else {
    specificCommsPayload = {
      ...specificCommsPayload,
      ...scheduleSpecificpayload,
      offering_variant_payload,
      [FORM_KEYS.audience_type]: audienceType,
      [FORM_KEYS.instalments_payload]: instalmentsPayload,
    };
  }

  try {
    const { status } = await dataProvider.custom_request(
      apiUrl,
      apiMethods.POST,
      specificCommsPayload
    );
    if (isRequestSuccessful(status)) {
      const successMessage = `${
        isEditMode ? "Updated" : "Created"
      } comm successfully!`;
      setCommDataSession({
        openCommModal: false,
      });
      notify(successMessage, "info");
      const path = app_pathnames[
        app_route_keys.manage_communications_of_listing
      ]({ listing_uuid: specificComms?.listing_uuid });
      history.push(
        appendUrlParams(path, {
          selected_switch_type: constants.toggleType.switches.findIndex(
            (switch_type) => switch_type === specificComms?.through
          ),
          selected_comm_type: constants.tabs.find(
            (item) => item.label === specificComms.type
          )?.value,
        })
      );
    }
  } catch (er) {
    const errorMessage =
      er.message || `Unable to ${isEditMode ? "edit" : "create"} comm !`;
    notify(errorMessage, notification_color_keys.error);
  }
};

/**
 * Sets communication data in the session storage.
 *
 * @param {Object} data - The communication data to store.
 */
export const setCommDataSession = (data) => {
  window.sessionStorage.setItem(comms.STORAGE_KEY, JSON.stringify(data));
};

/**
 * Retrieves communication data from the session storage.
 *
 * @returns {Object} The communication data stored in session storage, or null if it doesn't exist.
 */
export const getCommDataSession = () => {
  return JSON.parse(window.sessionStorage.getItem(comms.STORAGE_KEY));
};

export const calculateOpenRate = (partialValue, totalValue) => {
  const percent = (100 * partialValue) / totalValue;
  if (percent) return percent.toFixed(2);
  return 0;
};

/**
 * Prepare a communication title based on the provided properties and type.
 *
 * @param {Object} props - An object containing communication properties.
 * @param {string} type - The type of communication.
 * @returns {string} The prepared communication title.
 */
export const prepareCommTitle = (props, type) => {
  if (props?.comm_timing === 0) {
    return `${type} ${constants.title_type[props.comm_schedule][0]} ${
      constants.title_type[props.drip_type][0]
    } ${constants.title_type[props.drip_type][1]}`;
  }

  return `${type} ${props?.comm_timing} ${
    constants.comm_timing_choice[props.comm_timing_choice]
  } ${constants.title_type[props.comm_schedule][0]} ${
    constants.title_type[props.comm_schedule][1]
  }`;
};

const getQPPCommTypeDisabledOptions = ({
  isInstalmentsPlanTypeEnabled,
  isSubscriptionPlanTypeEnabled,
}) => {
  if (isInstalmentsPlanTypeEnabled || isSubscriptionPlanTypeEnabled)
    return [comms.COMM_FOLLOWUPS];

  return [comms.COMM_REMINDERS, comms.COMM_FOLLOWUPS];
};

/**
 * Get communication type options based on the specified offering type.
 *
 * @param {string} offering_type - The type of offering for which communication options are requested.
 * @returns {Array} An array of communication type options.
 */
export const getCommTypeOptions = (
  offering_type,
  isInstalmentsPlanTypeEnabled,
  isSubscriptionPlanTypeEnabled
) => {
  // Disable Options Map
  const mapObj = {
    // QuickPayments
    [schedule_type_names[schedule_types_ids.no_schedule]]:
      getQPPCommTypeDisabledOptions({
        isInstalmentsPlanTypeEnabled,
        isSubscriptionPlanTypeEnabled,
      }),
    //Branded community
    [schedule_type_names[schedule_types_ids.branded_community]]:
      isSubscriptionPlanTypeEnabled
        ? [comms.COMM_FOLLOWUPS]
        : [comms.COMM_REMINDERS, comms.COMM_FOLLOWUPS],
    // WhatsAppCommunity
    [schedule_type_names[schedule_types_ids.whatsapp]]:
      isSubscriptionPlanTypeEnabled
        ? [comms.COMM_FOLLOWUPS]
        : [comms.COMM_REMINDERS, comms.COMM_FOLLOWUPS],
    // RecorderContent
    [schedule_type_names[schedule_types_ids.content]]:
      isInstalmentsPlanTypeEnabled ? [] : [comms.COMM_REMINDERS],
  };
  return constants.create_comm.layout.comm_type.options(mapObj[offering_type]);
};

/**
 * @param {number} commSchedule comm schedule type, ref: COMM_SCHEDULE constant
 * @param {number} commTimingChoice timing choice in minutes, hours or days, ref: COMM_TIMING_CHOICE constant
 * @returns duration type for Communication Type dropdown
 */
export const getDurationType = (commSchedule, commTimingChoice) => {
  switch (commSchedule) {
    case COMM_SCHEDULE.BEFORE_EVENT:
      return DURATION_TYPE_FROM_TIMING_CHOICE_BEFORE_EVENT[commTimingChoice];
    case COMM_SCHEDULE.AFTER_EVENT:
      return DURATION_TYPE_FROM_TIMING_CHOICE_AFTER_EVENT[commTimingChoice];
    default:
      return COMMUNICATION_TIMING_AFTER_EVENT.HOURS_AFTER;
  }
};
